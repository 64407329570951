<template>
  <div>
    <vue-title :title="title" />
    <div class="content">
      <b-tabs
          active-nav-item-class="font-weight-bold text-danger" fill>
        <b-tab title="Lua Script" active>
          <vue-monaco-editor
            v-model="script"
            language="lua"
            theme="vs-dark"
            :options="MONACO_EDITOR_OPTIONS"
            height="85vh"
          />
        </b-tab>
        <b-tab title="Game Json">
          <vue-monaco-editor
              v-model="game"
              language="json"
              theme="vs-dark"
              :options="MONACO_EDITOR_OPTIONS"
              height="85vh"
          />
        </b-tab>
        <b-tab title="Config Json">
          <vue-monaco-editor
              v-model="config"
              language="json"
              theme="vs-dark"
              :options="MONACO_EDITOR_OPTIONS"
              height="85vh"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_MY_SCRIPT, SET_MY_GAME, SET_MY_CONFIG } from "@/store/games";

export default {
  components: {
  },
  data () {
    return {
      title: 'Pixel Quest – Редактор кода',
      gameConfigObj: null,
      MONACO_EDITOR_OPTIONS: {
        automaticLayout: true,
        formatOnType: true,
        formatOnPaste: true,
      },
    }
  },
  methods: {},
  computed: {
    script: {
      get () {
        return this.myScript
      },
      set (value) {
        this.$store.commit(SET_MY_SCRIPT, value)
      }
    },
    game: {
      get () {
        return this.myGame
      },
      set (value) {
        this.$store.commit(SET_MY_GAME, value)
      }
    },
    config: {
      get () {
        return this.myConfig
      },
      set (value) {
        this.$store.commit(SET_MY_CONFIG, value)
      }
    },
    ...mapGetters('stats', ['stats']),
    ...mapGetters(['gamesList', 'myScript', 'myGame', 'myConfig']),
  }
}
</script>

<style scoped>
.content {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
.margin-left-10 {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}
</style>
