<template>
  <div class="header">
    <b-navbar toggleable="md">
      <b-navbar-brand to="/">
        <img src="../../assets/logo.png" class="d-inline-block align-top" height="30">
      </b-navbar-brand>
      <template v-if="isAuthenticated">
        <b-navbar-toggle target="nav_collapse" />
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav>
            <b-nav-item style="list-style-type: none;" to="/"><b-icon icon="journal-code" /> Редактор кода</b-nav-item>
            <b-nav-item style="list-style-type: none;" to="/floor"><b-icon icon="grid3x3-gap-fill" /> Игровое поле</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-button size="sm" class="my-2 my-sm-0 margin-left-20" variant="success" @click="saveMyGame()">Сохранить</b-button>
            <b-button size="sm" class="my-2 my-sm-0 margin-left-20" variant="primary" @click="startMyGame()">Запустить</b-button>
          </b-navbar-nav>
        </b-collapse>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_FLOOR } from "@/store/floor";
import { GET_LOCATION_DATA } from "@/store/location";
import { GET_MY_GAME, SAVE_MY_GAME, START_MY_GAME } from "@/store/games";
import { host } from '@/config'
import store from '@/store'

export default {
  data() {
    return {}
  },
  methods: {
    routeIfNotAuthenticated(route) {
      if (!this.isAuthenticated && route.path !== '/login') {
        this.$router.push('/login')
      }
    },
    wsConnect() {
      if (this.authToken) {
        this.$connect('ws://' + host + '/ws?token=' + this.authToken, {
          store: store,
          format: 'json',
          reconnection: true, // whether to reconnect automatically
          reconnectionDelay: 5000,
        })
      } else {
        this.$disconnect()
      }
    },
    getMyGame() {
      if (this.authToken) {
        this.$store.dispatch(GET_MY_GAME).then(() => {
        }).catch(err => {
          this.$bvToast.toast(err.response ? err.response.data : err.message, {
            title: 'Ошибка получения игры',
            variant: 'danger',
            solid: true
          })
        })
      }
    },
    saveMyGame() {
      this.$store.dispatch(SAVE_MY_GAME).then(() => {
        this.$bvToast.toast('Игра успешно сохранена', {
          title: 'Успешно',
          variant: 'success',
          solid: true,
          autoHideDelay: 1000,
        })
      }).catch(err => {
        this.$bvToast.toast(err.response ? err.response.data : err.message, {
          title: 'Ошибка сохранения игры',
          variant: 'danger',
          solid: true
        })
      })
    },
    startMyGame() {
      this.saveMyGame()
      this.$store.dispatch(START_MY_GAME).then(() => {
        if (this.$router.currentRoute.path !== '/floor') {
          this.$router.push('/floor')
        } else {
          this.$store.dispatch('floor/' + GET_FLOOR)
        }
      }).catch(err => {
        this.$bvToast.toast(err.response ? err.response.data : err.message, {
          title: 'Ошибка запуска игры',
          variant: 'danger',
          solid: true
        })
      })
    }
  },
  created () {
    this.$store.dispatch('location/' + GET_LOCATION_DATA)
    this.routeIfNotAuthenticated(this.$router.currentRoute)
    this.getMyGame()
    this.wsConnect()
  },
  watch: {
    isAuthenticated(newValue) {
      if (!newValue) {
        this.$router.push('/login')
        this.$disconnect()
      }
      this.getMyGame()
      this.wsConnect()
    },
    $route (to) {
      this.routeIfNotAuthenticated(to)
      if (to.path === '/floor') {
        this.$store.dispatch('floor/' + GET_FLOOR)
      }
    },
  },
  computed: {
    ...mapGetters(['isConnected', 'gamesQuestV2List']),
    ...mapGetters('location', ['isAuthenticated', 'authToken']),
  }
}
</script>

<style scoped>
.navbar-brand {
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
}
.form-group {
  margin-bottom: 0;
}
.header {
  background-color: #171b21;
  border-bottom: 1px solid rgba(0,0,0,.125);
}
.navbar {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding-left: 10px;
  padding-right: 10px;
}
.navbar-toggler {
  background-color: #20252A;
}
.form-inline {
  margin-left: 10px;
  margin-bottom: 5px;
}
.padding {
  padding: .547rem 1.063rem;
}
.red {
  color: #dc3545;
}
.metrics-col {
  padding-right: 0.5rem;
  font-size: small;
  vertical-align:top;
}
.nav-item {
  font-size: small;
}
.margin-left-20 {
  margin-left: 20px;
}
</style>
