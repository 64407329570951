<template>
  <div
		:class="show ? '' : 'hidden'"
		class="absolute right-0 bg-bg z-30 border-2 border-solid border-green-light rounded bg-bg"
		:style="{ bottom: '-140px', right: '100px' }">
		<div class="w-32 h-32 rounded flex justify-center items-center">
			<div class="grid grid-cols-3">
				<div :style="{ height: '23px' }"></div>
				<div :style="{ height: '23px' }" class="relative">
					<img v-if="buttonClicked.includes('top')" class="absolute" :style="{ top: '0', left: 0 }" src="../../assets/sort-up-yellow.png" 
          @click="$emit('clickButton', 'top')"/>
					<img v-else class="absolute" :style="{ top: '0', left: 0 }" src="../../assets/sort-up.png" 
          @click="$emit('clickButton', 'top')"
					/>
				</div>
				<div :style="{ height: '23px' }"></div>

				<div :style="{ height: '53px' }" class="relative">
					<img
						v-if="buttonClicked.includes('left')"
						src="../../assets/sort-up-yellow.png"
						class="-rotate-90 absolute"
						:style="{ top: '16px', right: 0 }"
						@click="$emit('clickButton', 'left')"
					/>
						<img
						v-else
						src="../../assets/sort-up.png"
						class="-rotate-90 absolute"
						:style="{ top: '16px', right: 0 }"
						@click="$emit('clickButton', 'left')"
					/>
				</div>
				<div
					:style="{ height: '53px' }"
					class="relative flex items-center justify-center"
				>
					<div
						:style="{ width: '40px', height: '40px' }"
						:class="buttonClicked.includes('ok') ? 'border-yellow' : 'border-green-light'"
						class="relative flex justify-center items-center rounded-full border-2 border-solid"
						@click="$emit('clickButton', 'ok')"
					>
						<img 
						v-if="buttonClicked.includes('ok')"
						src="../../assets/Ok-yellow.png" class="absolute" :style="{ width: '75%' }" />
						<img 
						v-else
						src="../../assets/Ok.png" class="absolute" :style="{ width: '75%' }" />
					</div>
				</div>
				<div :style="{ height: '53px' }" class="relative">
					<img
						v-if="buttonClicked.includes('right')"
						src="../../assets/sort-up-yellow.png"
						class="rotate-90 absolute"
						:style="{ top: '16px', left: 0 }"
						@click="$emit('clickButton', 'right')"
					/>
					<img
						v-else
						src="../../assets/sort-up.png"
						class="rotate-90 absolute"
						:style="{ top: '16px', left: 0 }"
						@click="$emit('clickButton', 'right')"
					/>
				</div>

				<div :style="{ height: '23px' }"></div>
				<div :style="{ height: '23px' }" class="relative">
					<img
						v-if="buttonClicked.includes('bottom')"
						src="../../assets/sort-up-yellow.png"
						class="rotate-180 absolute"
						:style="{ top: '4px', left: 0 }"
						@click="$emit('clickButton', 'bottom')"
					/>
					<img
						v-else
						src="../../assets/sort-up.png"
						class="rotate-180 absolute"
						:style="{ top: '4px', left: 0 }"
						@click="$emit('clickButton', 'bottom')"
					/>
				</div>
				<div :style="{ height: '23px' }"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean, 
      default: false
    },
		buttonClicked: {
			type: () => [], // top | left | right | bottom | ok
			default: []
		}
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
.absolute {
  position: absolute;
}
.relative{
  position: relative;
}
.right-0 {
  right: 0;
}
.z-30 {
  z-index: 30;
}
.bg-bg {
  background-color: #0f181c;
}
.border-2 {
  border-width: 2px;
}
.border-solid {
  border-style: solid;
}
.border-green-light {
  /* Точное значение цвета зависит от настроек в вашем конфиге Tailwind */
  border-color:#B7FFC7; /* Замените #green-light на реальный цвет, определенный в конфиге */
}
.border-yellow {
	border-color: #fffd54;
}
.rounded {
  border-radius: 0.25rem; /* или другое значение в зависимости от настроек */
}
.w-32 {
  width: 8rem; /* 32px если базовый размер шрифта равен 16px */
}
.h-32 {
  height: 8rem; /* 32px если базовый размер шрифта равен 16px */
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.grid {
  display: grid;
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.rotate-90 {
  transform: rotate(90deg);
}
.-rotate-90 {
  transform: rotate(-90deg);
}
.rotate-180 {
  transform: rotate(180deg);
}
.rounded-full{
  border-radius: 50%;
}
.hidden{
  display: none;
}

</style>