<template>
  <div id="app">
    <Header v-if="!isBoard" />
    <div id="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Header from './components/header/Header'

export default {
  name: 'App',
  components: {
    Header
  },
  computed: {
    isBoard: function() {
      return this.$route.path === '/board'
    }
  }
}
</script>

<style>
body {
  background-color: #020408;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app a {
  text-decoration: none;
  color: #cad1d8;
  font-weight: bolder;
}
#app a:hover {
  color: #ffffff;
}
.btn {
  padding: 0 0.75rem;
}
.custom-select, .form-control {
  margin-bottom: 5px;
  height: calc(1.0em + 0.75rem + 2px);
  padding: 0 0.65rem 0 0.75rem
}
.input-group-text {
  height: calc(1.0em + 0.75rem + 2px);
}
.nav-link {
  padding: 0 1rem;
}
</style>
