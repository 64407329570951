import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import title from './Title'
import router from './router'
import store from './store'
import VueNativeSock from 'vue-native-websocket'
import { host } from '@/config'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

Vue.config.productionTip = false

Vue.component('vue-title', title)

Vue.use(VueNativeSock, 'ws://' + host + '/ws', { connectManually: true })
Vue.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
