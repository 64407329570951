import axios from 'axios'
import { serverURI } from '@/config'

export default {
  request (method, uri, data = null) {
    if (!method) {
      console.error('API function call requires method argument')
      return
    }

    if (!uri) {
      console.error('API function call requires uri argument')
      return
    }

    const token = localStorage.getItem('token') || ''
    if (token) {
      axios.defaults.headers.common.Authorization = token
    }

    const url = serverURI + uri
    return axios({ method, url, data })
  }
}
