<template>
  <div>
    <vue-title :title="title" />
<!--  =========== CONNECTING =========== -->
    <!-- Почему-то нельзя убрать !isConnected падает на обращении к floor[0], скорее всего пол обновляется итеративно -->
    <div v-if="!isConnected || floorStatus === 'loading'" class="connecting">
      <h5><b-icon icon="arrow-clockwise" animation="spin"/> Соединение...</h5>
    </div>
    <div v-else-if="floorStatus !== 'done'" class="connecting">
      <h5><b-icon icon="arrow-clockwise" animation="spin"/> Ожидание игры...</h5>
    </div>
    <div v-else class="content">

<!--  =========== Scoreboard =========== -->
    <template v-if="stats">
      <Board compact />
      <b-button size="lg" class="margin-left-10" @click="switchStage"><b-icon icon="chevron-double-right" /> След.этап</b-button>

      <b-button variant="warning" size="lg" class="margin-left-10" @click="pauseGame">
        <template v-if="stats.on_pause">
          <b-icon icon="play-fill" /> Снять с паузы
        </template>
        <template v-else>
          <b-icon icon="pause-fill" /> Пауза
        </template>
      </b-button>

      <b-button variant="danger" size="lg" class="margin-left-10" @click="stopGame()"><b-icon icon="stop-fill" /> Завершить</b-button>
    </template>

<!--  =========== FLOOR =========== -->
      <div :style="{ position: 'relative' }">
        <b-form-group class="nav-link click-select">
          <b>Клик:</b> <b-form-radio-group
              id="btn-radios-1"
              v-model="selectedClick"
              :options="clickOptions"
              size="md"
              name="radios-btn-default"
              buttons
          ></b-form-radio-group>
        </b-form-group>

        <Joystick :show="showJoystick"
        :button-clicked="buttonsOfJoystickClicked"
        @clickButton="clickJoystickButton"
        />
      </div>

      <table class="center">
        <tr>
          <!--  =========== ZERO CORNER =========== -->
          <th v-if="!zeroPixel?.click && !buttons[0]?.click" class="th-corner"></th>
          <th v-else-if="zeroPixel?.click"
              :class="['th-zero-pixel', getPixelClickClass(zeroPixel)]"
              :style="'background-color: ' + getColorStyle(zeroPixel?.color, zeroPixel?.bright)"
              @click="clickPixel(zeroPixel)">
              {{ zeroPixel?.click ? zeroPixel?.weight : '' }}</th>
          <th v-else-if="buttons[0]?.click"
              class="th-clicked-button"
              :style="'background-color: ' + getColorStyle(buttons[0].color, buttons[0].bright)"
              @click="clickButton(0)">
            К0</th>
          <!--  =========== TOP BUTTONS =========== -->
          <th v-for="(n, cIdx) in floor[0]" :key="'h'+cIdx"
              :class="getButtonClickClass(buttons[cIdx+1]) ? getButtonClickClass(buttons[cIdx+1]) : showButton(cIdx+1) ? '' : 'th-deactivated'"
              :style="'border-radius: 18px; background-color: ' + getColorStyle(buttons[cIdx+1]?.color, buttons[cIdx+1]?.bright)"
              @click="clickButton(cIdx+1)">
            К{{ cIdx+1 }}
          </th>
          <th class="th-corner"></th>
        </tr>
        <tr v-for="(row, rIdx) in floor" :key="'r'+rIdx">
          <!--  =========== LEFT BUTTONS =========== -->
          <th v-for="buttonNum in [2*floor[0].length+2*floor.length-rIdx]" :key="'b'+buttonNum"
              :class="getButtonClickClass(buttons[buttonNum]) ? getButtonClickClass(buttons[buttonNum]) : showButton(buttonNum) ? '' : 'th-deactivated'"
              :style="'border-radius: 25px; background-color: ' + getColorStyle(buttons[buttonNum]?.color, buttons[buttonNum]?.bright)"
              @click="clickButton(buttonNum)">
            К{{ buttonNum }}
          </th>
          <!--  =========== PIXELS =========== -->
          <td v-for="(pixel, cIdx) in row" :key="'c'+cIdx"
              :style="'background-color: ' + getColorStyle(pixel.color, pixel.bright)"
            :class="[getPixelClickClass(pixel)]"
            @click="clickPixel(pixel)">
              {{ paramToDisplay === '' ?
                    pixel.click ? pixel.weight : ''
                 : pixel[paramToDisplay] }}
          </td>
          <!--  =========== RIGHT BUTTONS =========== -->
          <th v-for="buttonNum in [floor[0].length+rIdx+1]" :key="'b'+buttonNum"
              :class="getButtonClickClass(buttons[buttonNum]) ? getButtonClickClass(buttons[buttonNum]) : showButton(buttonNum) ? '' : 'th-deactivated'"
              :style="'border-radius: 25px; background-color: ' + getColorStyle(buttons[buttonNum]?.color, buttons[buttonNum]?.bright)"
              @click="clickButton(buttonNum)">
            К{{ floor[0].length+rIdx+1 }}
          </th>
        </tr>
        <tr>
          <th class="th-corner"></th>
          <!--  =========== BOTTOM BUTTONS =========== -->
            <template v-for="buttonNum in [2*floor[0].length+floor.length]">
              <th v-for="(n, cIdx) in floor[0]" :key="'f'+cIdx+'b'+buttonNum"
                  :class="getButtonClickClass(buttons[buttonNum-cIdx]) ? getButtonClickClass(buttons[buttonNum-cIdx]) : showButton(buttonNum-cIdx) ? '' : 'th-deactivated'"
                  :style="'border-radius: 25px; background-color: ' + getColorStyle(buttons[buttonNum-cIdx].color, buttons[buttonNum-cIdx].bright)"
                  @click="clickButton(buttonNum-cIdx)">
                К{{ buttonNum-cIdx }}
              </th>
            </template>
            <th class="th-corner"></th>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { SEND_MESSAGE } from '@/store/ws'
import { PAUSE_GAME, STOP_GAME, SWITCH_STAGE } from '@/store/games'
import { GET_FLOOR } from '@/store/floor'
import { DEFECT_BUTTON, DEFECT_PIXEL } from '@/store/commands'
import { mapGetters } from 'vuex'
import { getColorStyle, toLetters } from '@/functions/functions'
import Joystick from "./Joysick.vue"
import Board from './Board.vue'

export default {
  components: {
    Board,
    Joystick
  },
  data () {
    return {
      title: 'Pixel Quest – Игровое поле',
      selectedClick: 'perm_click',
      clickOptions: [
        { text: 'Короткий', value: 'short_click' },
        { text: 'Постоянный', value: 'perm_click' },
        { text: 'Дефект', value: 'defect_click' },
        { text: 'Джойстик', value: 'joystick_click' }
      ],
      showJoystick: false,
      buttonsOfJoystickClicked: [],
      joystickButtonTimeout: {
        top: false, 
        left: false, 
        bottom: false,
        right: false, 
        ok: false
      }
    }
  },
  methods: {
    toLetters,
    getColorStyle,
    showButton(buttonNum) {
      if (!this.buttonNums) return false
      return this.buttonNums.includes(buttonNum)
    },
    sendJoystickMessage(direction) {
        const object = this.createDirectionJoystickObject(direction, true)
        this.$store.dispatch(SEND_MESSAGE, JSON.stringify(object))
        this.joystickButtonTimeout[direction] = setTimeout(() => {
          this.buttonsOfJoystickClicked = [...this.buttonsOfJoystickClicked].filter(elem => elem !== direction)
          this.joystickButtonTimeout[direction] = false
          const object = this.createDirectionJoystickObject(direction, false)
          this.$store.dispatch(SEND_MESSAGE, JSON.stringify(object))
        }, 500)
    },
    clickJoystickButton(direction) {
      switch (direction) {
        case 'top': {
          this.buttonsOfJoystickClicked = this.buttonsOfJoystickClicked.includes('top') ? 
                                          this.buttonsOfJoystickClicked : [...this.buttonsOfJoystickClicked, 'top']
          if (this.joystickButtonTimeout.top === false) { 
            this.sendJoystickMessage('top')
          } else {
            clearTimeout(this.joystickButtonTimeout.top)
            this.sendJoystickMessage('top')
          }
          break
        }
        case 'left': {
          this.buttonsOfJoystickClicked = this.buttonsOfJoystickClicked.includes('left') ? 
                                          this.buttonsOfJoystickClicked : [...this.buttonsOfJoystickClicked, 'left']
          if (this.joystickButtonTimeout.left === false) {
            this.sendJoystickMessage('left')
          } else {
            clearTimeout(this.joystickButtonTimeout.left)
            this.sendJoystickMessage('left')
          }

          break
        }
        case 'ok': { 
          this.buttonsOfJoystickClicked = this.buttonsOfJoystickClicked.includes('ok') ? 
                                          this.buttonsOfJoystickClicked : [...this.buttonsOfJoystickClicked, 'ok']
          if (this.joystickButtonTimeout.ok === false) {
            this.sendJoystickMessage('ok')
          } else {
            clearTimeout(this.joystickButtonTimeout.ok)
            this.sendJoystickMessage('ok')
          }
          break
        }
        case 'right': {
          this.buttonsOfJoystickClicked = this.buttonsOfJoystickClicked.includes('right') ? 
                                          this.buttonsOfJoystickClicked : [...this.buttonsOfJoystickClicked, 'right']
          if (this.joystickButtonTimeout.right === false) {
            this.sendJoystickMessage('right')
          } else {
            clearTimeout(this.joystickButtonTimeout.right)
            this.sendJoystickMessage('right')
          }
          break
        }
        case 'bottom': {
          this.buttonsOfJoystickClicked = this.buttonsOfJoystickClicked.includes('bottom') ? 
                                          this.buttonsOfJoystickClicked : [...this.buttonsOfJoystickClicked, 'bottom']
          if (this.joystickButtonTimeout.bottom === false) {
            this.sendJoystickMessage('bottom')
          } else {
            clearTimeout(this.joystickButtonTimeout.bottom)
            this.sendJoystickMessage('bottom')
          }
          break
        }
      }
    },
    createDirectionJoystickObject(direction, val) {
       const object = {
        message_type: "click_button",
        gamepad_address: 1
      };
      switch (direction) {
        case 'top': { 
          object.gamepad_up_click = val;
          break
        }
        case 'left': {
          object.gamepad_left_click = val;
          break
        }
        case 'ok': { 
          object.gamepad_trigger_click = val;
          break
        }
        case 'right': {
          object.gamepad_right_click = val;
          break
        }
        case 'bottom': {
          object.gamepad_down_click = val;
          break
        }
      }
      return object
    },


    clickButton(button) {
      if (this.selectedClick === 'defect_click') {
        this.$store.dispatch(DEFECT_BUTTON, {
          button_address: button,
          defect: !this.buttons[button].sd,
        })
        return
      }

      let click = {
        message_type: 'click_button',
        button_address: button,
        click: !this.buttons[button].click,
      }
      this.$store.dispatch(SEND_MESSAGE, JSON.stringify(click))

      if (click.click && this.selectedClick === 'short_click') {
        click.click = false
        setTimeout(() => {
          this.$store.dispatch(SEND_MESSAGE, JSON.stringify(click))
        }, 200)
      }
    },
    clickPixel(pixel) {
      if (this.selectedClick === 'defect_click') {
        this.$store.dispatch(DEFECT_PIXEL, {
          pixel_x: pixel.x,
          pixel_y: pixel.y,
          defect_a: !this.floor[pixel.y][pixel.x].sda,
          defect_b: !this.floor[pixel.y][pixel.x].sdb,
        })
        return
      }

      let click = {
        message_type: 'click_pixel',
        pixel_x: pixel.x,
        pixel_y: pixel.y,
        click: !this.floor[pixel.y][pixel.x].click,
        weight_a: this.floor[pixel.y][pixel.x].click? 0 : 50000,
        weight_b: this.floor[pixel.y][pixel.x].click? 0 : 50000,
      }
      this.$store.dispatch(SEND_MESSAGE, JSON.stringify(click))

      if (click.click && this.selectedClick === 'short_click') {
        click.click = false
        click.weight_a = 0
        click.weight_b = 0
        setTimeout(() => {
          this.$store.dispatch(SEND_MESSAGE, JSON.stringify(click))
        }, 200)
      }
    },
    getPixelClickClass(pixel) {
      if (pixel?.snr || (!pixel?.dual_ch && pixel?.sda) || (pixel?.sda && pixel?.sdb)) {
        return 'red-border'
      }
      if (pixel?.scze || pixel?.scse || pixel?.sda || pixel?.sdb) {
        return 'yellow-border'
      }
      if (pixel?.click) {
        return 'greenyellow-border'
      }
      return ''
    },
    getButtonClickClass(button) {
      if (button?.sd) {
        return 'th-red-border-button'
      }
      if (button?.click) {
        return 'th-clicked-button'
      }
      return ''
    },
    pauseGame() {
      this.$store.dispatch(PAUSE_GAME)
    },
    switchStage() {
      this.$store.dispatch(SWITCH_STAGE)
    },
    stopGame() {
      this.$store.dispatch(STOP_GAME)
      this.$router.push('/')
    },
  },
  created () {
    this.$store.dispatch('floor/' + GET_FLOOR)
  },
  computed: {
    ...mapGetters('floor', ['floorStatus', 'floor', 'buttons', 'buttonNums', 'zeroPixel', 'paramToDisplay']),
    ...mapGetters('stats', ['stats']),
    ...mapGetters(['isConnected'])
  },
  watch: {
    selectedClick(newVal) {
      if (newVal === 'joystick_click') this.showJoystick = true
      else this.showJoystick = false
    }
  }
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0px;
  background-color: #0e1116;
  color: #cad1d8;
  table-layout: fixed;
  font-size: small;
}
tr {
  display: flex;
  height: 36px;
  overflow-y: hidden;
}
td, th {
  width: 36px;
  min-width: 36px;
  height: 36px;
  max-height: 36px;
  padding-top: 6px;
}
th {
  padding-top: 10px;
}
td {
  border: 3px solid black;
}
td:hover {
  border-color: greenyellow;
  cursor: pointer;
  padding-top: 10px;
}
th:hover {
  border: 3px solid greenyellow;
  border-radius: 18px;
  cursor: pointer;
  padding-top: 9px;
}
.th-corner:hover {
  border: none;
  border-radius: 0;
  cursor: default;
}
.greenyellow-border {
  border-color: greenyellow;
}
.th-zero-pixel {
  padding-top: 10px;
  border-width: 3px;
  border-style: solid;
  border-radius: 0;
  cursor: pointer;
}
.th-zero-pixel:hover {
  border-radius: 0;
}
.th-clicked-button {
  border: 3px solid greenyellow;
  padding-top: 10px;
  border-radius: 18px;
  cursor: pointer;
}
.red-border {
  border-color: red;
}
.red-border:hover {
  border-color: red;
}
.yellow-border {
  border-color: yellow;
}
.th-red-border-button{
  border: 3px solid red;
  padding-top: 10px;
  border-radius: 18px;
  cursor: pointer;
}
.th-red-border-button:hover{
  border-color: red;
}
.th-yellow-border-button{
  border: 3px solid yellow;
  padding-top: 10px;
  border-radius: 18px;
  cursor: pointer;
}
.content {
  display: inline-block;
  padding: 20px;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.fixed-plugin .fixed-plugin-button {
  background: #d0d0d0;
  border-radius: 50%;
  bottom: 30px;
  right: 30px;
  z-index: 990;
  box-shadow: 0 2px 10px 0 rgba(255,255,255,.5);
  cursor: pointer;
}
.fixed-plugin :hover {
  background: #fff;
  border-radius: 50%;
}
.py-2 {
  padding-top: .5rem!important;
  padding-bottom: .5rem!important;
}
.px-3 {
  padding-right: .5rem!important;
  padding-left: .5rem!important;
}
.margin-left-10 {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
}
.connecting {
  color: #cad1d8;
  padding: 20px;
}
.left {
  padding-left: 1rem!important;
  padding-right: 1rem!important;
  text-align: start;
}
.card-body {
  padding: 0.75rem;
}
.btn-send {
  float: right;
  margin: 5px;
}
.th-deactivated {
  font-size: 0;
}
.th-deactivated:hover {
  cursor: auto;
  border: none;
  border-radius: 18px;
}
.click-select {
  color: #f5eded;
}
</style>
