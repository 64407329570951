import api from '../api'
import Vue from 'vue'
// import { BToast } from 'bootstrap-vue'
import { formatWeight  } from '@/functions/functions'

export const GET_FLOOR = 'GET_FLOOR'
export const SET_FLOOR = 'SET_FLOOR'
export const SET_FLOOR_STATUS = 'SET_FLOOR_STATUS'
export const SET_SEND_CLICKS = 'SET_SEND_CLICKS'
export const SET_SEGMENTS = 'SET_SEGMENTS'
export const SET_BUTTONS = 'SET_BUTTONS'
export const SET_PARAM_TO_DISPLAY = 'SET_PARAM_TO_DISPLAY'

export default {
  namespaced: true,
  state: {
      status: '',
      floor: null,
      zeroPixel: null,
      buttons: [],
      buttonNums: [],
      paramToDisplay: '',
  },
  getters: {
      floorStatus: state => state.status,
      floor: state => state.floor,
      buttons: state => state.buttons,
      buttonNums: state => state.buttonNums,
      zeroPixel: state => state.zeroPixel,
      paramToDisplay: state => state.paramToDisplay,
  },
  actions: {
      [GET_FLOOR]: ({ commit }) => {
          commit(SET_FLOOR_STATUS, 'loading')
          return new Promise((resolve, reject) => {
              api
                  .request('GET', '/floor')
                  .then(response => {
                      resolve(response)
                      commit(SET_FLOOR, response.data)
                      commit(SET_FLOOR_STATUS, 'done')
                  })
                  .catch(err => {
                      reject(err)
                      commit(SET_FLOOR_STATUS, 'error')
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                                  break
                          }
                      }
                  })
          })
      },
      [SET_SEND_CLICKS]: ({ commit }, value ) => {
          return new Promise((resolve) => {
              commit(SET_SEND_CLICKS, value)
              resolve()
          })
      },
      [SET_PARAM_TO_DISPLAY]: ({ commit }, value ) => {
          return new Promise((resolve) => {
              commit(SET_PARAM_TO_DISPLAY, value)
              resolve()
          })
      }
  },
  mutations: {
      [SET_FLOOR_STATUS]: (state, status) => {
          state.status = status
      },
      [SET_FLOOR]: (state, data) => {
          state.buttonNums = data.button_nums;
          for(let button of data.buttons) {
              state.buttons[button.can_address] = button;
          }

          let floor = [];
          for(let segment of data.segments) {
              for (let [num, pixel] of segment.pixels.entries()) {
                  if (pixel.x >= data.pixel_cols ||
                      pixel.y >= data.pixel_rows) continue;
                  pixel.num = num+1;
                  pixel.weight = formatWeight(pixel.weight_a+pixel.weight_b);
                  pixel.weight_a = formatWeight(pixel.weight_a);
                  pixel.weight_b = formatWeight(pixel.weight_b);
                  pixel.can_address = segment.can_address;
                  if (!floor[pixel.y]) floor[pixel.y]=[];
                  floor[pixel.y][pixel.x] = pixel;
              }
          }
          state.floor = floor
      },
      [SET_SEND_CLICKS]: (state, value) => {
          state.sendClicks = value
      },
      [SET_PARAM_TO_DISPLAY]: (state, value) => {
          state.paramToDisplay = value
      },
      [SET_BUTTONS]: (state, buttons) => {
          for(let button of buttons) {
              state.buttons[button.can_address] = button;
          }
          Vue.set(state.buttons, 0, state.buttons[0]) // update it in the grid to call rerender
      },
      [SET_SEGMENTS]: (state, segments) => {
          if (!state.floor) return;

          for(let segment of segments) {
              for(let [num, pixel] of segment.pixels.entries()) {
                  if (pixel.y >= state.floor.length ||
                      pixel.x >= state.floor[0].length) continue;

                  pixel.num = num+1;
                  pixel.weight = formatWeight(pixel.weight_a+pixel.weight_b);
                  pixel.weight_a = formatWeight(pixel.weight_a);
                  pixel.weight_b = formatWeight(pixel.weight_b);
                  pixel.can_address = segment.can_address;
                  pixel.adc_rc_filter_k = segment.adc_rc_filter_k;
                  pixel.min_calibration_threshold = segment.min_calibration_threshold;
                  pixel.click_threshold_gram = segment.click_threshold_gram;
                  pixel.click_hysteresis_gram = segment.click_hysteresis_gram;
                  pixel.click_dupl_per = segment.click_dupl_per;
                  pixel.click_off_dupl_msgs = segment.click_off_dupl_msgs;

                  if (pixel.x < 0) { // ZeroSegment
                      state.zeroPixel = pixel
                  } else {
                      state.floor[pixel.y][pixel.x] = pixel
                  }

                  // if (pixel.sce === pixel.epoch) {
                  //     let variant = 'success';
                  //     let message = '';
                  //
                  //     if (pixel.snr) {
                  //         variant = 'danger';
                  //         message = 'Ошибка датчика';
                  //     } else {
                  //         if (pixel.scze) {
                  //             variant = 'warning';
                  //             message = 'Ошибка калибровки нуля';
                  //         }
                  //         if (pixel.scse) {
                  //             variant = 'warning'
                  //             if (message !== '') message += ', ';
                  //             message += 'Ошибка калибровки шкалы';
                  //         }
                  //         if (pixel?.sd) {
                  //             variant = 'warning'
                  //             if (message !== '') message += ', ';
                  //             message += 'Дефектован';
                  //         }
                  //     }
                  //     if (message === '') {
                  //         message = 'Статус ОК';
                  //     }
                  //     new BToast().$bvToast.toast(message, {
                  //         title: "Пиксель " + pixel.can_address + '.' + pixel.num,
                  //         toaster: "b-toaster-top-right",
                  //         variant: variant,
                  //         solid: true,
                  //         appendToast: false
                  //     })
                  // }
              }
          }

          Vue.set(state.floor, 0, state.floor[0]) // update it in the grid to call rerender
      },
  }
}
