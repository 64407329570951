import api from '../api'

// PIXEL
export const SET_PIXEL_COLOR_AND_BRIGHT = 'SET_PIXEL_COLOR_AND_BRIGHT'
export const CALIBRATE_ZERO = 'CALIBRATE_ZERO'
export const CALIBRATE_SENS = 'CALIBRATE_SENS'
export const READ_PIXEL_REG = 'READ_PIXEL_REG'
export const WRITE_PIXEL_REG = 'WRITE_PIXEL_REG'
export const RESTART_SEGMENT = 'RESTART_SEGMENT'
export const DEFECT_PIXEL = 'DEFECT_PIXEL'
export const TEST_PIXEL_LED = 'TEST_PIXEL_LED'

// BUTTON
export const SET_BUTTON_COLOR_AND_BRIGHT = 'SET_BUTTON_COLOR_AND_BRIGHT'
export const RESTART_BUTTON = 'RESTART_BUTTON'
export const READ_BUTTON_REG = 'READ_BUTTON_REG'
export const WRITE_BUTTON_REG = 'WRITE_BUTTON_REG'
export const DEFECT_BUTTON = 'DEFECT_BUTTON'
export const TEST_BUTTON_LED = 'TEST_BUTTON_LED'

// GLOBAL
export const SET_GLOBAL_COLOR_AND_BRIGHT = 'SET_GLOBAL_COLOR_AND_BRIGHT'
export const CALIBRATE_GLOBAL_ZERO = 'CALIBRATE_GLOBAL_ZERO'
export const READ_GLOBAL_REG = 'READ_GLOBAL_REG'
export const WRITE_GLOBAL_REG = 'WRITE_GLOBAL_REG'
export const RESTART_GLOBAL = 'RESTART_GLOBAL'
export const TEST_GLOBAL_LED = 'TEST_GLOBAL_LED'

export default {
  state: {},
  getters: {},
  actions: {
      // PIXEL
      [SET_PIXEL_COLOR_AND_BRIGHT]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/color', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [CALIBRATE_ZERO]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/calibrate_zero', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [CALIBRATE_SENS]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/calibrate_sens', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [READ_PIXEL_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/read_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [WRITE_PIXEL_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/write_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [RESTART_SEGMENT]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/restart', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [DEFECT_PIXEL]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/defect', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [TEST_PIXEL_LED]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/pixel/test_led', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },

      // BUTTON
      [SET_BUTTON_COLOR_AND_BRIGHT]: ( _, button ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/color', button)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [READ_BUTTON_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/read_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [WRITE_BUTTON_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/write_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [RESTART_BUTTON]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/restart', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [DEFECT_BUTTON]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/defect', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [TEST_BUTTON_LED]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/button/test_led', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },

      // GLOBAL
      [SET_GLOBAL_COLOR_AND_BRIGHT]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/color', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [CALIBRATE_GLOBAL_ZERO]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/calibrate_zero', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [READ_GLOBAL_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/read_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [WRITE_GLOBAL_REG]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/write_reg', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [RESTART_GLOBAL]: ( _, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/restart', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      },
      [TEST_GLOBAL_LED]: (_, pixel ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/global/test_led', pixel)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                  })
          })
      }
  },
  mutations: {}
}
