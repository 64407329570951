import Vue from 'vue'
import Vuex from 'vuex'
import floor from './floor'
import ws from './ws'
import stats from './stats'
import games from './games'
import commands from './commands'
import location from './location'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    floor,
    ws,
    stats,
    games,
    commands,
    location
  }
})
