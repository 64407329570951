<template>
  <div v-if="!stats" class="connecting">
    <router-link to="/">
        <h5 v-if="!isConnected"><b-icon icon="arrow-clockwise" animation="spin"/> Соединение с контроллером...</h5>
        <h5 v-else-if="!compact"><b-icon icon="joystick"/> Ожидание игры...</h5>
    </router-link>
  </div>
  <div v-else :class="['pixelquest',!compact ? 'expand' : '']">
    <div :class="[!compact ? 'pixelquest__wrapper' : '']">
      <div class="pixelquest__top">
        <div v-if="!compact" class="pixelquest__logo">
          <router-link to="/">
            <img src="../../assets/logo.png" alt="logo">
          </router-link>
        </div>
        <div class="pixelquest__title">
          {{ stats?.name }}
        </div>
        <div class="pixelquest__time">
          <img src="../../assets/time.svg" alt="time">
          {{ formatTotalTime(stats?.game_duration_sec) }}
        </div>
        <div class="pixelquest__score">
          <template v-if="stats?.target_score > 0">
            <template v-for="(n, pIdx) in stats.players">
              <div v-if="stats?.players[pIdx].color > 0" :key="'p'+pIdx" class="progress-bar-player">
                <div class="progress-bar-color" :style="'background: ' + getColorStyle(stats?.players[pIdx].color, 7)">
                  <div class="progress-bar-color__bar"></div>
                  <div class="progress-bar-color__progress" :style="'width: ' + ( stats?.players[pIdx].score ? 100-100*stats?.players[pIdx].score/stats?.target_score : '98') + '%'"></div>
                </div>
                <div style="flex-grow: 1;max-width:3rem;">{{ stats?.players[pIdx].score }}</div>
              </div>
            </template>
          </template>
        </div>
        <div v-if="stats?.target_color" class="pixelquest__score">
          <h4 class="fantasy">
            Цель: &nbsp;<b-icon icon="square-fill" :style="'color: ' + getColorStyle(stats?.target_color, 7)"/>
            {{ colorText(stats?.target_color) }}
          </h4>
        </div>
      </div>
      <div class="pixelquest__bottom">
        <template v-if="stats?.target_score <= 0">
          <div v-if="stats.total_lives > 0 || stats?.total_stars > 0" class="pixelquest__content">
            <div class="pixelquest__block">
              <div class="pixelquest__health">
                <img src="../../assets/health.svg" alt="health">
                <span v-if="stats.total_lives > 0">{{ stats.current_lives > 0 ? stats.current_lives : 0 }}</span>
                <span v-else>∞</span>
              </div>
              <div class="pixelquest__star">
                <img src="../../assets/star.svg" alt="star">
                <span v-if="stats?.total_stars > 0">{{ stats?.current_stars }} / {{ stats?.total_stars }}</span>
                <span v-else>∞</span>
              </div>
            </div>
          </div>
          <div v-if="stats?.stage_total_duration > 0" class="progress-bar">
            <div class="progress-bar__bar"></div>
            <div class="progress-bar__progress" :style="'width: ' + (100 - 100*stats?.stage_left_duration/stats?.stage_total_duration) + '%'"></div>
          </div>
        </template>
        <div v-if="!stats?.on_pause" class="pixelquest__other-time">
          <template v-if="stats?.stage_num > 0 &&
            (stats?.total_stages === 0 || (stats?.total_stages > 0 && stats?.stage_num <= stats?.total_stages))">
            Этап {{ stats?.stage_num }} {{ stats?.stage_num <= stats?.total_stages ? '/ ' + stats?.total_stages : '' }}
          </template> <b-icon icon="stopwatch-fill" /> {{ formatStageTime(stats?.stage_left_duration) }}
        </div>
        <div v-else class="pixelquest__other-time">[ НА ПАУЗЕ ]</div>
      </div>
      <template v-if="!compact">
        <div class="pixelquest__cube pixelquest__cube_tl">
          <img src="../../assets/cube-1.svg" alt="cube">
        </div>
        <div class="pixelquest__cube pixelquest__cube_tr">
          <img src="../../assets/cube-2.svg" alt="cube">
        </div>
        <div class="pixelquest__cube pixelquest__cube_bl">
          <img src="../../assets/cube-3.svg" alt="cube">
        </div>
        <div class="pixelquest__cube pixelquest__cube_br">
          <img src="../../assets/cube-4.svg" alt="cube">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getColorStyle } from '@/functions/functions'

export default {
  props: {
    compact: Boolean
  },
  computed: {
    ...mapGetters('stats', ['stats']),
    ...mapGetters(['isConnected'])
  },
  methods: {
    getColorStyle,
    colorText(color) {
      switch (color) {
        case 1: return 'Красный'
        case 2: return 'Зеленый'
        case 3: return 'Желтый'
        case 4: return 'Синий'
        case 5: return 'Фиолетовый'
        case 6: return 'Голубой'
        case 7: return 'Белый'
        default: return ''
      }
    },
    formatTotalTime(secs) {
      const hh = Math.floor(secs / 3600)
      secs -= hh * 3600
      const mm = Math.floor(secs / 60)
      secs -= mm * 60
      return (hh ? hh + ' ч ' : '') + (mm ? mm + ' м ' : '') + secs + ' сек'
    },
    formatStageTime(secs) {
      const mm = Math.floor(secs / 60)
      secs -= mm * 60
      return (mm < 10 ? '0' : '') + mm + ':' + (secs < 10 ? '0' : '') + secs
    },
    formatScore(score) {
      let output = '';
      const thousands = Math.floor(score / 1000)
      if (thousands) {
        score -= thousands * 1000
        output = thousands + ' '
        output += score < 100 ? '0' : ''
        output += score < 10 ? '0' : ''
      }
      output += score
      return output
    }
  }
}
</script>

<style scoped>
/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
  text-decoration: none;
}

a:hover  {
  text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

html {
  font-size: calc(40px + 56 * ((100vw - 1920px) / 4096));
}
body {
  font-family: 'Rubik', sans-serif;
  background: #0F181D;
}
.pixelquest {
  /*padding: 70px;*/
  padding: 0.5rem;
  width: 100%;
  background: #0F181D;
}
.expand {
  min-height: 100vh;
  height: 100vh;
}
.pixelquest__wrapper {
  width: 100%;
  height: 100%;
  background: #152127;
  /*border-radius: 30px;*/
  border-radius: 0.75rem;
  /*padding: 40px 0 30px;*/
  padding: 1rem 0 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  /*gap: 1rem;*/
}
.pixelquest__logo {
  width: 100%;
  text-align: center;
  /*padding: 0 0 50px;*/
  padding: 0 0 1.25rem;
}
.pixelquest__logo img {
  /*width: 151.333px;*/
  width: 6.5rem;
  /*height: 80px;*/
}
.pixelquest__title {
  color: #C3FDFF;
  text-align: center;
  /*font-size: 72px;*/
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 120%;
  /*margin-bottom: 40px;*/
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.pixelquest__time {
  display: flex;
  align-items: center;
  /*gap: 30px;*/
  gap: 0.75rem;
  color: #FFF;
  /*font-size: 40px;*/
  font-size: 1rem;
  line-height: 120%;
  justify-content: center;
}
.pixelquest__score {
  color: #FFF;
  text-align: center;
  /*font-size: 72px;*/
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  /*margin-bottom: 40px;*/
  margin-bottom: 0rem;
  padding: 1rem 1rem;
}
.pixelquest__time img{
  height: 2rem;
}
.pixelquest__content {
  /*margin-top: 128px;*/
  /*margin-top: 1.2rem;*/
}
.pixelquest__block {
  display: flex;
  justify-content: space-between;
  /*max-width: 1260px;*/
  /*min-width: 1260px;*/
  max-width: 31.5rem;
  /*margin: 0 auto 80px;*/
  margin: 0 auto 1rem;
  color: #FFF;
  /*font-size: 96px;*/
  font-size: 1.5rem;
  line-height: 120%;
  /*padding: 0 20px;*/
  padding: 0 0.5rem;
}
.pixelquest__health {
  display: flex;
  /*gap: 40px;*/
  gap: 1rem;
}
.pixelquest__star {
  display: flex;
  /*gap: 40px;*/
  gap: 1rem;
}
.pixelquest__health img,
.pixelquest__star img {
  /*height: 100px;*/
  height: 2rem;
  width: auto;
}
.progress-bar {
  position: relative;
  /*max-width: 1560px;*/
  /*min-width: 1560px;*/
  max-width: 39rem;
  /*margin: 0 auto 60px;*/
  margin: 0 auto 0rem;
  /*padding: 0 20px;*/
  //padding: 0 0.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
}
.progress-bar__bar {
  /*height: 60px;*/
  height: 1.2rem;
  width: 100%;
  /*border-radius: 20px;*/
  border-radius: 0.5rem;
  //background: #374B56;
  background: linear-gradient(93deg, #e53e3e 0%, #007d53 100%);
}
.progress-bar__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #374B56;
}
.progress-bar-player {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar-color {
  position: relative;
  /*max-width: 1560px;*/
  /*min-width: 1560px;*/
  max-width: 39rem;
  width: 20rem;
  /*margin: 0 auto 60px;*/
  //margin: 0 0 1.5rem;
  /*padding: 0 20px;*/
  //padding: 0 0.5rem;
  overflow: hidden;
  border-radius: 0.5rem;
}
.progress-bar-color__bar {
  /*height: 60px;*/
  height: 1rem;
  width: 100%;
  /*border-radius: 20px;*/
  border-radius: 0.5rem;
}
.progress-bar-color__progress {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: #374B56;
}
.pixelquest__other-time {
  background: #0F181D;
  padding: 31px 86px;
  padding: 0.775rem 0.775rem;
  color: #FFF;
  text-align: center;
  //font-size: 48px;
  font-size: 1rem;
  line-height: 120%;
  width: 100%;
  /*max-width: 400px;*/
  /*min-width: 400px;*/
  max-width: 20rem;
  /*border-radius: 15px;*/
  border-radius: 0.375rem;
  margin: 0 auto;
}
.pixelquest__cube {
  position: absolute;
  height: 3.25rem;
  width: auto;
  z-index: 0;
}
.pixelquest__cube img {
  height: 3.25rem;
}
.pixelquest__cube_tl {
  top: 0.75rem;
  left: 0.75rem;
}
.pixelquest__cube_tr {
  top: 0.75rem;
  right: 0.75rem;
}
.pixelquest__cube_bl {
  bottom: 0.75rem;
  left: 0.75rem;
}
.pixelquest__cube_br {
  bottom: 0.75rem;
  right: 0.75rem;
}
.connecting {
  color: #cad1d8;
  padding: 20px;
  font-size: 2rem;
}
</style>
