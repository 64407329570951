import api from "@/api";

export const SET_STATS = 'SET_STATS'
export const GET_VERSION = 'GET_VERSION'
export const SET_VERSION = 'SET_VERSION'

export default {
  namespaced: true,
  state: {
      stats: null,
      version: '',
  },
  getters: {
      stats: state => state.stats,
      version: state => state.version,
  },
  actions: {
      [GET_VERSION]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              api
                  .request('GET', '/version')
                  .then(response => {
                      resolve(response)
                      commit(SET_VERSION, response.data)
                  })
                  .catch(err => {
                      reject(err)
                      console.log(err)
                  })
          })
      },
  },
  mutations: {
      [SET_STATS]: (state, stats) => {
          if (stats.id === 0) { // demo game
              state.stats = null
          } else {
              stats.players.sort((a, b) => { return b.score - a.score })
              state.stats = stats
          }
      },
      [SET_VERSION]: (state, data) => {
          state.version = data.version
      },
  }
}
