import api from '../api'

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_SET_STATUS_LOADING = 'SET_AUTH_STATUS_LOADING'
export const GET_LOCATION_DATA = 'GET_LOCATION_DATA'
export const SET_LOCATION_STATUS_LOADING = 'SET_LOCATION_STATUS_LOADING'
export const SET_LOCATION = 'SET_LOCATION'

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        authStatus: '',
        locationStatus: '',
        location: {}
    },

    getters: {
        authToken: state => state.token,
        isAuthenticated: state => !!state.token,
        authStatusSuccess: state => state.authStatus === 'success',
        authStatusLoading: state => state.authStatus === 'loading',
        isProfileLoading: state => state.locationStatus === 'loading',
        locationData: state => state.location
    },

    actions: {
        [GET_LOCATION_DATA]: ({ commit }) => {
            commit(SET_LOCATION_STATUS_LOADING)
            return new Promise((resolve, reject) => {
                api
                    .request('GET', '/location')
                    .then(response => {
                        resolve(response)
                        commit(SET_LOCATION, response.data)
                    })
                    .catch(err => {
                        reject(err)
                        commit(SET_LOCATION, {})
                        if (err.response) {
                            switch (err.response.status) {
                                case 401:
                                    commit(AUTH_LOGOUT)
                            }
                        }
                    })
            })
        },
        [AUTH_REQUEST]: ({ commit }, user) => {
            return new Promise((resolve, reject) => {
                commit(AUTH_SET_STATUS_LOADING)
                api
                    .request('POST', '/login', user)
                    .then(response => {
                        const data = response.data
                        commit(AUTH_SUCCESS)
                        commit(AUTH_SET_TOKEN, data.token)
                        commit(SET_LOCATION, data)
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err)
                        commit(AUTH_ERROR)
                        commit(AUTH_LOGOUT)
                    })
            })
        },
        [AUTH_LOGOUT]: ({ commit }) => {
            return new Promise((resolve) => {
                commit(AUTH_LOGOUT)
                resolve()
            })
        }
    },

    mutations: {
        [AUTH_SET_STATUS_LOADING]: (state) => {
            state.authStatus = 'loading'
        },
        [AUTH_SUCCESS]: (state) => {
            state.authStatus = 'success'
        },
        [AUTH_SET_TOKEN]: (state, token) => {
            localStorage.setItem('token', token)
            state.token = token
        },
        [AUTH_ERROR]: (state) => {
            state.authStatus = 'error'
        },
        [AUTH_LOGOUT]: (state) => {
            localStorage.removeItem('token')
            state.token = ''
            state.authStatus = 'logout'
        },
        [SET_LOCATION_STATUS_LOADING]: (state) => {
            state.locationStatus = 'loading'
        },
        [SET_LOCATION]: (state, data) => {
            state.locationStatus = ''
            state.location = data
        }
    }
}
