export function toLetters(num) {
  "use strict";
  if (num <= 0) {
    return 'Z';
  }
  let mod = num % 26,
      pow = num / 26 | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
  return pow ? toLetters(pow) + out : out;
}

export function fromLetters(str) {
  "use strict";
  let out = 0, len = str.length, pos = len;
  while (--pos > -1) {
    out += (str.charCodeAt(pos) - 64) * Math.pow(26, len - 1 - pos);
  }
  return out;
}

export function formatWeight(weight) {
  return (weight/1000).toFixed(1);
}

export function getColorStyle(color, bright) {
  const alpha = bright / 7
  switch (color) {
    case 0: return ''
    case 1: return 'rgba(255,0,0,' + alpha + ')'
    case 2: return 'rgba(0,255,0,' + alpha + ')'
    case 3: return 'rgba(255,255,0,' + alpha + ')'
    case 4: return 'rgba(0,0,255,' + alpha + ')'
    case 5: return 'rgba(255,0,255,' + alpha + ')'
    case 6: return 'rgba(0,255,255,' + alpha + ')'
    case 7: return 'rgba(255,255,255,' + alpha + ')'
  }
}

export function getBrightPercent(index) {
  return [0, 15, 30, 45, 60, 70, 85, 100][index]
}

export function ruConfigLabel(label) {
  switch (label) {
    case "bright": return "Яркость пикселей"
    case "no_loss": return "Игра без проигрыша"
    case "start_lives": return "Количество жизней"
    case "buttons_bright": return "Яркость кнопок"
    case "start_from_stage": return "Начать с этапа"
    case "frame_duration_coeff": return "Коэфф. замедления анимации"
    case "stage_duration_coeff": return "Коэфф. длительности этапов"
    case "delay": return "Задержка (мс)"
    case "draw_delay": return "Задержка отрисовки (мс)"
    case "click_delay": return "Задержка клика (мс)"
    case "stages_qty": return "Количество этапов"
    case "stop_color": return "Стоп цвет"
    case "start_color": return "Старт цвет"
    case "loop_game": return "Зациклить игру"
    case "wrong_color": return "Ошибочный цвет"
    case "win_duration": return "Длительность победы (мс)"
    case "stop_duration": return "Длительность паузы (мс)"
    case "stage_duration": return "Длительность этапа (мс)"
    case "start_duration": return "Длительность старта (мс)"
    case "game_duration": return "Длительность игры (сек)"
    case "move_pixels": return "Перемещать пиксели"
    case "points_to_win": return "Очков для победы"
    case "filling_percentage": return "Процент заполнения"
    case "move_all_pixels": return "Перемещать пиксели соперников"
    case "show_pattern": return "Показывать паттерн"
    case "replay_period": return "Реплей интервал (мс)"
    case "reset_on_error": return "Сбрасывать на ошибку"
    case "show_errors": return "Показывать ошибки"
    case "replay_line_duration": return "Длительность проигрыша линии (мс)"
    case "success_effect_duration": return "Длительность эффекта успеха (мс)"
    case "burn_delay": return "Задержка обжигания лавой (мс)"
    case "block_size": return "Размер сегмента"
    case "burn_effect_duration": return "" // disable this field for configuration
    case "burn_effect_duration_phase_on": return "" // disable this field for configuration
    case "burn_effect_duration_phase_off": return "" // disable this field for configuration
    case "wrong_effect_duration": return "" // disable this fields for configuration
    case "circle_draw_delay": return "" // disable this fields for configuration
    default: return label
  }
}
