import Vue from 'vue'
import router from '@/router'
import { BToast } from 'bootstrap-vue'

export const SEND_MESSAGE = 'SEND_MESSAGE'

export default {
    state: {
        socket: {
            isConnected: false,
        },
        lastToastTime: 0,
    },
    getters: {
        isConnected: state => state.socket.isConnected,
    },
    mutations:{
        SOCKET_ONOPEN (state, event)  {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
            console.log(Date.now(), "WS connected")
        },
        SOCKET_ONCLOSE (state)  {
            state.socket.isConnected = false
            console.log(Date.now(), "WS disconnected")
        },
        SOCKET_ONERROR ()  {
            // console.error(state, event)
            console.log(Date.now(), "WS error", event)
            new BToast().$bvToast.toast('Ошибка подключения к WebSocket', {
                title: 'Ошибка подключения',
                variant: 'danger',
                solid: true
            })
        },
        SOCKET_ONMESSAGE (state, message)  {
            switch (message.message_type) {
                case 'floor':
                    // console.log(Date.now(), "WS Segments", message)
                    if (router.currentRoute.path === '/floor') {
                        this.commit('floor/SET_SEGMENTS', message.segments, {root: true})
                        this.commit('floor/SET_BUTTONS', message.buttons, {root: true})
                    }
                    break
                case 'stats':
                    // console.log("WS Stats", message)
                    this.commit('stats/SET_STATS', message, { root: true })
                    break
                case 'debug':
                    console.log("log.print:", message.log)
                    break
                case 'error':
                    console.log("WS Error", message)
                    if (Math.floor(new Date() - state.lastToastTime) < 4000) {
                        break
                    }
                    new BToast().$bvToast.toast(message.error, {
                        title: "Ошибка",
                        toaster: "b-toaster-top-right",
                        variant: 'danger',
                        solid: true,
                        appendToast: false
                    })
                    state.lastToastTime = new Date();
                    break
            }
        },
        SOCKET_RECONNECT() {
            // console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR() {},
    },
    actions: {
        SEND_MESSAGE(context, message) {
            Vue.prototype.$socket.send(message)
        }
    }
}