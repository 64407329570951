import api from '../api'

export const SET_MY_SCRIPT = 'SET_MY_SCRIPT'
export const SET_MY_GAME = 'SET_MY_GAME'
export const SET_MY_CONFIG = 'SET_MY_CONFIG'
export const GET_MY_GAME = 'GET_MY_GAME'
export const SAVE_MY_GAME = 'SAVE_MY_GAME'
export const START_MY_GAME = 'START_MY_GAME'
export const PAUSE_GAME = 'PAUSE_GAME'
export const STOP_GAME = 'STOP_GAME'
export const SWITCH_STAGE = 'SWITCH_STAGE'

export default {
  state: {
      isOnPause: false,
      gamesList: [],
      gamesQuestV2List: [],
      myScript: '',
      myGame: '',
      myConfig: ''
  },
  getters: {
      isOnPause: state => state.isOnPause,
      gamesList: state => state.gamesList,
      gamesQuestV2List: state => state.gamesQuestV2List,
      myScript: state => state.myScript,
      myGame: state => state.myGame,
      myConfig: state => state.myConfig,
  },
  actions: {
      [GET_MY_GAME]: ( { commit } ) => {
          return new Promise((resolve, reject) => {
              api
                  .request('GET', '/my_game')
                  .then(response => {
                      resolve(response)
                      // decodeURIComponent(escape(atob(data))) - b64_to_utf8
                      // btoa(unescape(encodeURIComponent(str))) - utf8_to_b64
                      let utf8script = decodeURIComponent(escape(atob(response.data.script)))
                      commit(SET_MY_SCRIPT, utf8script)

                      let utf8game = decodeURIComponent(escape(atob(response.data.game)))
                      commit(SET_MY_GAME, JSON.stringify(JSON.parse(utf8game),null,2))

                      let utf8config = decodeURIComponent(escape(atob(response.data.config)))
                      commit(SET_MY_CONFIG, JSON.stringify(JSON.parse(utf8config),null,2))


                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
      [SAVE_MY_GAME]: ( { commit, getters } ) => {
          return new Promise((resolve, reject) => {
              const myGame = {
                  script: btoa(unescape(encodeURIComponent(getters.myScript))),
                  game: btoa(unescape(encodeURIComponent(getters.myGame))),
                  config: btoa(unescape(encodeURIComponent(getters.myConfig))),
              }
              api
                  .request('POST', '/my_game/save', myGame)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
      [START_MY_GAME]: ( { commit, getters } ) => {
          return new Promise((resolve, reject) => {
              const myGame = {
                  script: btoa(unescape(encodeURIComponent(getters.myScript))),
                  game: btoa(unescape(encodeURIComponent(getters.myGame))),
                  config: btoa(unescape(encodeURIComponent(getters.myConfig))),
              }
              api
                  .request('POST', '/my_game/start', myGame)
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
      [PAUSE_GAME]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/game/pause')
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
      [STOP_GAME]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/game/stop')
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
      [SWITCH_STAGE]: ({ commit }) => {
          return new Promise((resolve, reject) => {
              api
                  .request('POST', '/game/switch_stage')
                  .then(response => {
                      resolve(response)
                  })
                  .catch(err => {
                      reject(err)
                      if (err.response) {
                          switch (err.response.status) {
                              case 401:
                                  commit('location/AUTH_LOGOUT', {}, {root: true})
                          }
                      }
                  })
          })
      },
  },
  mutations: {
      [SET_MY_SCRIPT]: (state, data) => {
          state.myScript = data
      },
      [SET_MY_GAME]: (state, data) => {
          state.myGame = data
      },
      [SET_MY_CONFIG]: (state, data) => {
          state.myConfig = data
      },
  }
}
