<template>
  <div>
    <vue-title :title="title" />
    <b-row class="padding-top-bottom">
      <b-col sm="4"></b-col>
      <b-col sm="4" class="text-center">
        <form class="login-form" @submit.prevent="login">
          <h3><b>Авторизация</b></h3>

          <div class="input-group">
            <span class="input-group-addon"><b-icon icon="house-door" class="fa-sm"/></span>
            <b-form-input placeholder="Имя" v-model="name" :state="nameState"/>
          </div>

          <div class="input-group">
            <span class="input-group-addon"><b-icon icon="key" class="fa-sm"/></span>
            <b-form-input type="password" placeholder="Пароль" v-model="password" :state="passwordState"/>
            <b-form-invalid-feedback>
              {{badResponse}}
            </b-form-invalid-feedback>
          </div>
          <button v-if="!authStatusLoading" type="submit" class="btn btn-primary">Вход</button>
          <button v-else class="btn btn-primary" disabled="true"><b-icon icon="arrow-clockwise" animation="spin"/></button>
        </form>
      </b-col>
      <b-col sm="4"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_REQUEST, GET_LOCATION_DATA } from "@/store/location";

export default {
  components: {},
  data () {
    return {
      title: 'Авторизация',
      badResponse: '',
      name: '',
      password: '',
      nameState: null,
      passwordState: null
    }
  },
  methods: {
    login () {
      let { name, password } = this

      this.badResponse = ''
      this.nameState = (name !== '')
      this.passwordState = (password !== '')

      if (!this.nameState || !this.passwordState) {
        return
      }

      this.$store.dispatch('location/' + AUTH_REQUEST, { name, password })
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => {
            this.passwordState = false
            if (err.response) {
              switch (err.response.status) {
                case 403:
                  this.badResponse = "Неверный пароль"
                  break;
                default:
                  this.badResponse = "Неизвестная ошибка"
              }
            }
          })
    },
  },
  created () {
    this.$store.dispatch('location/' + GET_LOCATION_DATA)
  },
  computed: {
    ...mapGetters('location', ['authStatusLoading', 'locationData']),
  }
}
</script>

<style scoped>
h3 {
  color: #cad1d8;
}
.input-group {
  padding-top: 10px;
  padding-bottom: 10px;
}
.error {
  color: red;
}
.btn {
  width: 180px;
  margin: 10px auto;
  display: block;
  height: 40px;
}
.form-control {
  height: 37px;
}
.input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 6px 5px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #d0cfcf;
  border: solid #cccccc;
  border-width: 1px 0 1px 1px;
}
.padding-top-bottom {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-left-right {
  padding-left: 15px;
  padding-right: 15px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #a9abad;
  opacity: 1;
}
</style>
